.container {
  /* width: 100vw; */
  width: calc(100% - 50px);
  height: 100vh;
  /* padding: 20px; */
  flex-direction: column;
  overflow: auto;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  display: none;
}

@media (max-width: 1366px) {
  .container {
    width: 1310px;
    overflow: hidden;
  }


  .modalHeader{
    width: 1200px !important;
    margin-left: 0px !important;
  }

}




.modal {
  background-color: white;
  position: relative;
  width: 1400px;
  /* height: 95%; */
  max-height: 95%;
  /* max-height: 600px; */
  /* left: 200px; */
  top: 10px;
  margin: auto;
  color: black;
  z-index: 2000;
  overflow: hidden;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.active {
  display: block;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  align-items: start;
  margin-left: 30px;
}

.modalHeader h4 {
  margin-left: 10px;
  font-weight: 400;
  font-size: 16px;
}

.modalButtonDiv {
  display: flex;
  width: 1200px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.modalBody {
  width: 100%;
  height: 75vh;
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  padding: 10px;
}