.context-menu {
    visibility: hidden;
    opacity: 0;
    background-color: #DEEFE7;
    position: fixed;
    z-index: 5;
    color: black;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #aaa;
    font-size: 0.875rem;
    width: 100%;
    max-width: 250px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
}

.context-menu.active {
    visibility: visible;
    opacity: 1;
    display: flex;
    flex-direction: column;
}

.context-menu button {
    width: 100%;
    background-color: transparent;
    border: 0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 5px 8px;
    cursor: pointer;
    color: black;
}

.context-menu button:hover{
    background-color: #033259;
    color: white;
}