.habGridItem {
  gap: 50px;
  border: 1px solid lightgray;
  margin: -5px;
  margin-bottom: 15px;
  border-radius: 5px;
  display: flex !important;
  align-content: stretch !important;
  padding: 10px;
}

.habLabel {
  font-size: 13px !important;
  font-weight: bold;
  height: 50px;
}

.habTagGroup{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.habTagItem {

}

.tag-add-btn {
  margin: 0 -20px -20px 0 !important;
}
